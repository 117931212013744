import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
import './assets/css/index.css'
import scroll from 'vue-seamless-scroll'

import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)
Vue.use(ElementUI)
Vue.use(scroll)
Vue.use(dataV)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
