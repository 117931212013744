import http from '@/utils/request'

let apiUrl = ''
if (process.env.VUE_APP_ENV === 'dev') {
  apiUrl = '/api'
}
// 询留言TOP
export const getmessageTop = (params) =>
  http.get(apiUrl + `/message/messageBoard/messageTop`, params)
// 查询留言数量
export const getmessageCount = (params) =>
  http.get(apiUrl + `/message/messageBoard/messageCount`, params)
// 新增留言
export const addmessageBoard = (data, config = {}) =>
  http.post(apiUrl + `/message/messageBoard/scanSave`, data, config)
