import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      title: '电子留言簿系统',
    },
    component: HomeView,
  },
  {
    path: '/m',
    name: 'm',
    meta: {
      title: '留言登记',
    },
    component: () => import('../views/AboutView.vue'),
  },
]

const router = new VueRouter({
  // mode: 'hash',
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
