<template>
    <div class="content bg">
        <dv-full-screen-container>
            <dv-loading v-if="ifloading">Loading...</dv-loading>
            <!-- 第一行 -->
            <div class="tc title-box">
                <label for="" style="font-size: 48px;font-weight: 700; letter-spacing: 4px;">电子留言簿系统</label>
            </div>
            <!-- 第二行 -->
            <div class="module-box">
                <div style="flex:0 1 70%;">
                    <dv-border-box-8>
                        <div class="title-H">
                            <div style="width: 120px; line-height: 60px;">
                                <span style="font-size: 28px;">留言板</span>
                            </div>
                        </div>
                        <vue-seamless-scroll :data="listData" class="warp" :class-option="classOption">
                            <ul class="item">
                                <li v-for="(item, index) in listData" :key="index">
                                    <div class="module-flex" style="width: 100%; border-bottom: 1px solid rgba(2, 231, 243, 1);">
                                        <div style="width: 240px; padding: 20px 20px 20px 20px;">
                                            <div class="module-flex" style="padding-bottom: 10px;">
                                                <div style="width: 80px; margin-right: 2px; background: rgb(252 205 29 / 20%); border-radius: 5px 0px 0px 5px;">
                                                    <span class="title">留言人</span>
                                                </div>
                                                <div style="flex: 1; background: rgb(1 247 253 / 20%); border-radius: 0px 5px 5px 0px;">
                                                    <span style="margin-left: 10px; " v-text="item.name"></span>
                                                </div>
                                            </div>
                                            <div class="module-flex">
                                                <div style="width: 80px;margin-right: 2px; background: rgb(252 205 29 / 20%); border-radius: 5px 0px 0px 5px; ">
                                                    <span class="title">留言时间</span>
                                                </div>
                                                <div style="flex: 1; background: rgb(1 247 253 / 20%);border-radius: 0px 5px 5px 0px;">
                                                    <span style="margin-left: 10px;" v-text="item.createTime"></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="flex: 1; margin: 20px; border: 1px solid rgba(2, 231, 243, 1);">
                                            <span class="datacontent">{{item.message}}</span>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </vue-seamless-scroll>
                    </dv-border-box-8>
                </div>
                <div style="flex: 0 1 30%;">
                    <div>
                        <dv-border-box-8 style="height: 120px; margin-bottom: 30px;">
                            <div style="display: flex; justify-content: center; align-items: center;height: inherit;">
                                <span style="color: rgba(220, 176, 24, 1);font-size: 36px;padding-right: 30px;">今日留言数量</span>
                                <span style="color:#b4fdff;font-size: 36px;"> {{counts.countDay}} 条</span>
                            </div>
                        </dv-border-box-8>
                    </div>
                    <div>
                        <dv-border-box-8 style="height: 120px;margin-bottom: 30px;">
                            <div style="display: flex; justify-content: center; align-items: center;height: inherit;">
                                <span style="color: rgba(220, 176, 24, 1);font-size: 36px;padding-right: 30px;">本月留言数量</span>
                                <span style="color: #b4fdff;font-size: 36px;"> {{counts.countMonth}} 条</span>
                            </div>
                        </dv-border-box-8>
                    </div>
                    <div>
                        <dv-border-box-8 style="height: 120px;margin-bottom: 30px;">
                            <div style="display: flex; justify-content: center; align-items: center;height: inherit;">
                                <span style="color: rgba(220, 176, 24, 1);font-size: 36px;padding-right: 30px;">累计留言数量</span>
                                <span style="color: #b4fdff;font-size: 36px;"> {{counts.count}} 条</span>
                            </div>
                        </dv-border-box-8>
                    </div>
                    <div>
                        <dv-border-box-8 style="height: 450px;">
                            <div style="padding: 10px 50px;">
                                <img src="../assets/img/qrcode.png" style="height: 430px" alt="Image">
                            </div>
                        </dv-border-box-8>
                    </div>
                </div>
            </div>

        </dv-full-screen-container>
    </div>
</template>

<script>
import { getmessageTop, getmessageCount } from "../api/index";
import moment from "moment";
export default {
    name: "HomeView",
    data() {
        return {
            ifloading: true,
            listData: [],
            classOption: {
                singleHeight: 115,
                waitTime: 1000,
            },
            counts: {},
        };
    },
    created() {
        setTimeout(() => {
            this.ifloading = false;
        }, 1000);
    },
    mounted() {
        this.getTop();
        this.getcount();
        this.initWebsocket();
    },
    methods: {
        initWebsocket() {
            setInterval(() => {
                this.listData = [];
                this.getTop();
                this.getcount();
            }, 60000);
        },
        // 查询留言TOP
        getTop() {
            getmessageTop().then((res) => {
                // console.log(res);
                this.listData = res;
                this.listData.unshift(res[res.length - 1]);
                this.listData.splice(res.length - 1, 1);
                // console.log(this.listData);
            });
        },
        // 查询统计信息
        getcount() {
            getmessageCount().then((res) => {
                this.counts = res;
            });
        },
    },
};
</script>
<style >
.warp {
    height: 830px;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
}
.warp ul {
    list-style: none;
    padding: 0 3px;
    margin: 0 auto;
}
.warp li,
a {
    display: block;
    height: 115px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
}
.title {
    color: #fccd1d;
    margin-left: 10px;
}
.title-H {
    height: 65px;
    background: url("../assets/img/titleBg.svg") no-repeat top 2px left 2px;
    background-size: 140px 55px;
    text-align: center;
    color: #b4fdff;
}
.datacontent {
    margin: 2px 0px 4px 4px;

    height: auto;
    text-indent: 2em;
    line-height: 24px;

    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    word-break: break-all;
}
</style>
